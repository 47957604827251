<template>
  <!--begin::Accordion-->
  <div
    class="accordion accordion-light accordion-light-borderless accordion-svg-toggle"
    id="accGTM"
  >
    <!--begin::Item-->
    <div class="card">
      <!--begin::Header-->
      <div class="card-header" id="headingOne1">
        <div
          v-b-toggle.collapseOne1
          variant="primary"
          class="card-title"
          aria-expanded="true"
          role="button"
        >
          <span class="svg-icon svg-icon-primary">
            <!--begin::Svg Icon | path:/media/svg/icons/Navigation/Angle-double-right.svg-->
            <inline-svg
              src="media/svg/icons/Navigation/Angle-double-right.svg"
            ></inline-svg>
            <!--end::Svg Icon-->
          </span>
          <div class="card-label text-dark pl-4">
            Passo 1 - Adicione uma nova TAG
          </div>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <b-collapse
        id="collapseOne1"
        class="collapse show"
        aria-labelledby="headingOne1"
        data-parent="#accGTM"
      >
        <div class="card-body text-dark-50 font-size-lg pl-12">
          Selecione o website que deseja integrar o aplicativo de consentimento
          Consensu.IO no GTM e clique sobre o menu Tags no menu lateral esquerdo
          e depois clique sobre o botão “Nova”:
        </div>
        <div class="rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12">
          <img src="media/integrations/gtm/passo1.png" class="w-100" alt="" />
        </div>
      </b-collapse>
      <!--end::Body-->
    </div>
    <!--end::Item-->
    <!--begin::Item-->
    <div class="card">
      <!--begin::Header-->
      <div class="card-header " id="headingOne2">
        <div
          class="card-title"
          v-b-toggle.collapseOne2
          aria-expanded="true"
          role="button"
        >
          <span class="svg-icon svg-icon-primary">
            <!--begin::Svg Icon | path:/media/svg/icons/Navigation/Angle-double-right.svg-->
            <inline-svg
              src="media/svg/icons/Navigation/Angle-double-right.svg"
            ></inline-svg>

            <!--end::Svg Icon-->
          </span>
          <div class="card-label text-dark pl-4">
            Passo 2 - Configure sua TAG
          </div>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <b-collapse
        id="collapseOne2"
        class="collapse show"
        aria-labelledby="headingOne2"
        data-parent="#accGTM"
      >
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            a) Clique sobre o botão Configuração da tag ou no ícone do lápis ao
            lado direito:
          </p>
        </div>
        <div
          class="card-body rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12"
        >
          <img src="media/integrations/gtm/passo2.png" class="w-100" alt="" />
        </div>
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>b) Escolha “HTML personalizado” como o tipo de tag.</p>
        </div>
        <div
          class="card-body rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12"
        >
          <img src="media/integrations/gtm/passo3.jpg" class="w-100" alt="" />
        </div>
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            c) Na parte superior renomeie sua nova tag com o nome que desejar
            “Consensu TAG” em nosso exemplo. Copie o código abaixo na caixa HTML
            , substituindo o valor do atributo ‘key’ para a sua chave de cliente
            Consensu.
          </p>

          <highlight-code lang="js" class="language-json">
            <!-- <script> -->
            <!-- (function() { var el = document.createElement("script");
            el.setAttribute("src", "https://consensu.io/bundle.min.js");
            el.setAttribute( "key", "APLIQUE_CHAVE_DO_SITE" );
            el.setAttribute("charset", "utf-8"); el.setAttribute("debug",
            "true"); document.body.appendChild(el); })(); -->
            <!-- </script> -->
            {{ code }}
          </highlight-code>
        </div>

        <div
          class="card-body rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12"
        >
          <img src="media/integrations/gtm/passo4.jpg" class="w-100" alt="" />
        </div>
      </b-collapse>
      <!--end::Body-->
    </div>
    <!--end::Item-->

    <!--begin::Item-->
    <div class="card">
      <!--begin::Header-->
      <div class="card-header" id="headingOne3">
        <div
          class="card-title collapsed"
          v-b-toggle.collapseOne3
          aria-expanded="false"
          role="button"
        >
          <span class="svg-icon svg-icon-primary">
            <!--begin::Svg Icon | path:/media/svg/icons/Navigation/Angle-double-right.svg-->
            <inline-svg
              src="media/svg/icons/Navigation/Angle-double-right.svg"
            ></inline-svg>
            <!--end::Svg Icon-->
          </span>
          <div class="card-label text-dark pl-4">
            Passo 3 - Configure o Acionamento da TAG
          </div>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <b-collapse
        id="collapseOne3"
        class="collapse show"
        aria-labelledby="headingOne3"
        data-parent="#accGTM"
      >
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>a) Clique sobre o item “Acionamento”</p>
        </div>
        <div
          class="card-body rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12"
        >
          <img src="media/integrations/gtm/passo2.png" class="w-100" alt="" />
        </div>

        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>b) Selecione a opção “All Pages”</p>
        </div>
        <div
          class="card-body rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12"
        >
          <img src="media/integrations/gtm/passo5.jpg" class="w-100" alt="" />
        </div>

        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            c) Ao final sua tela deve ficar parecida como esta. Clique em
            salvar.
          </p>
        </div>
        <div
          class="card-body rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12"
        >
          <img src="media/integrations/gtm/passo6.png" class="w-100" alt="" />
        </div>
      </b-collapse>
      <!--end::Body-->
    </div>
    <!--end::Item-->
    <!--begin::Item-->
    <div class="card">
      <!--begin::Header-->
      <div class="card-header" id="headingOne4">
        <div
          class="card-title collapsed"
          v-b-toggle.collapseOne4
          aria-expanded="false"
          role="button"
        >
          <span class="svg-icon svg-icon-primary">
            <!--begin::Svg Icon | path:/media/svg/icons/Navigation/Angle-double-right.svg-->

            <inline-svg
              src="media/svg/icons/Navigation/Angle-double-right.svg"
            ></inline-svg>

            <!--end::Svg Icon-->
          </span>
          <div class="card-label text-dark pl-4">
            Passo 4 - Publique as Alterações
          </div>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <b-collapse
        id="collapseOne4"
        class="collapse show"
        aria-labelledby="headingOne4"
        data-parent="#accGTM"
      >
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            a) Após os procedimentos realizados acima, será a hora de publicar
            as alterações e assim implementar a tag Consensu em seu website.
            Clique sobre o botão “Enviar” ao lado superior direito.
          </p>
        </div>
        <div
          class="card-body rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12"
        >
          <img src="media/integrations/gtm/passo7.png" class="w-100" alt="" />
        </div>
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            b) Informe um nome e uma descrição para a sua publicação e clique em
            "Publicar".
          </p>
        </div>
        <div
          class="card-body rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12"
        >
          <img src="media/integrations/gtm/passo7_1.png" class="w-100" alt="" />
        </div>
      </b-collapse>
      <!--end::Body-->
    </div>
    <!--end::Item-->
    <!--begin::Item-->
    <div class="card">
      <!--begin::Header-->
      <div class="card-header" id="headingOne5">
        <div
          class="card-title collapsed"
          v-b-toggle.collapseOne5
          aria-expanded="false"
          role="button"
        >
          <span class="svg-icon svg-icon-primary">
            <!--begin::Svg Icon | path:/media/svg/icons/Navigation/Angle-double-right.svg-->

            <inline-svg
              src="media/svg/icons/Navigation/Angle-double-right.svg"
            ></inline-svg>

            <!--end::Svg Icon-->
          </span>
          <div class="card-label text-dark pl-4">
            Passo 5 - Valide sua instalação
          </div>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <b-collapse
        id="collapseOne5"
        class="collapse show"
        aria-labelledby="headingOne5"
        data-parent="#accGTM"
      >
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            Entre em seu website e verifique se o box de consentimento é
            apresentado
          </p>
        </div>
        <div
          class="card-body rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12"
        >
          <img src="media/integrations/gtm/passo8.jpg" class="w-100" alt="" />
        </div>
      </b-collapse>
      <!--end::Body-->
    </div>
    <!--end::Item-->
  </div>
  <!--end::Accordion-->
</template>

<script>
export default {
  name: "integration-gtm",
  computed: {
    code() {
      return `
         ${"<"}${"script"}${">"}
	          (function () {
              var el = document.createElement('script');
              el.setAttribute('src', 'https://consensu.io/bundle.min.js');
              el.setAttribute('key', 'CHAVE_DO_SEU_SITE');
              el.setAttribute('charset', 'utf-8');
              el.setAttribute('debug', 'true');
              document.body.appendChild(el);
            })();
         ${"</"}${"script"}${">"}
      `;
    }
  }
};
</script>
